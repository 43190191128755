<template>
  <div id="Work-List">
		<div class="table">
			<div class="header">
				<div class="col cover">{{L("投稿作品")}}</div>
				<div class="col title">{{L("作品名称")}}</div>
				<div class="col code">{{L("作品编号")}}</div>
				<div class="col status">{{L("状态")}}</div>
				<!-- <div class="col action"></div> -->
			</div>
			<div class="list">
				<div v-for="item in list" :key="item.id" class="item flex middle" @click="doEdit(item)">
					<div class="col cover">
						<template v-if="item.content.images && item.content.images.length">
							<CoverWrap :src="item.content.images[0].path + '?x-oss-process=image/resize,w_400'" width="230px" height="144px"/>
						</template>
					</div>
					<div class="col title">{{item.content.title}}</div>
					<div class="col code">{{$root.createWID(item)}}</div>
					<div class="col status">{{L(item.awardWorkStatusName)}}</div>
					
					<!-- <div class="col action"></div> -->
				</div>
				<div class="empty" v-if="!list || !list.length">{{L("暂无内容")}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import CoverWrap from '/src/components/CoverWrap.vue';
export default {
  name: 'Work-List',
	components:{
		CoverWrap	
	},
  props: {
    
		
  },
  data(){
    return {
      list:[],
    }
  },
	async mounted(){
		var list = await this.$root.getCurrentUserWork();
		
		this.list = list;
	},
	
  methods:{
    doEdit(item){
			this.$root.toPage("WorkEdit",item);
		}
  } 
}
</script>

<style scoped lang="less">
#Work-List{
	
}
.table{
	
	.header,.list{
		
		.col{
			padding: 15px 10px;
			width: 110px;
			text-align: center;
			// flex-shrink: 0;
			&.cover{
				width:230px;
				flex-shrink: 0;
				img{
					width:100%;
					max-width: 100%;
				}
			}
			&.title{
				width:100%;
			}
			&.code{
				width:145px;
				flex-shrink: 0;
			}
			&.status{
				width:120px;
				flex-shrink: 0;
			}
			&.action{
				width:100px;
				flex-shrink: 0;
			}
		}
	}
	
	
	.header{
		line-height: 1;
		background-color: #000000;
		line-height: 31px;
		display: flex;
		color: #FFF;
		font-size:16px;
		.col{
			padding: 0 0 0 0 ;
		}
	}
	.list{
		padding-bottom:80px;
		line-height: 1.5;
		font-size:14px;
		color:#000;
		.empty{
			height:80px;
			line-height: 80px;
			text-align: center;
			color:#333;
		}
		.item{
			
			cursor: pointer;
		}
		.col{
			padding: 15px 10px;
			&.cover{
				padding:15px 0 0;
			}
			&.status{
				color:#E60012
			}
		}
	}
}
</style>

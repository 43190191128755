<template>
	<div class="main-wrap">
		<div class="flex w100">
			<div><MainMenu :list="[{title:L('投稿信息'),path:'/User/Home'},{title:L('上传作品'),path:'/User/Work'}]"/></div>

			<div id="Page-User-Home" class="w720">
				<div class="title-h2">SUBMITTER INFO</div>
				<div class="title-h1">{{L("投稿信息")}}</div>
				<div class="mt-20"></div>
				
				<template v-if="dataLoading">
					<img src="@/assets/common/loading_d.gif" />
				</template>
				<template v-else>
					<div v-if="!isEdit" class="info-box">
						<div class="mt-10"></div>
						<div class="unitName">{{$root.userData.config.unitName}}</div>
						<div class="mt-10"></div>
						<div>
							<p v-if="$root.userData.config.unitTeam">{{L("团队名称")}}： {{$root.userData.config.unitTeam}}</p>
							<p>{{L("联系邮箱")}}： {{$root.userData.config.email}}</p>
							<p>{{L("所在城市")}}： {{$root.userData.config.city}}</p>
							<p>{{L("通信地址")}}： {{$root.userData.config.address}}</p>
							<p>{{L("联系电话")}}： {{$root.userData.config.tel}}</p>
							<p>{{L("微信")}}： {{$root.userData.config.wechat}}</p>
							<p>{{L("官网")}}： {{$root.userData.config.website}}</p>
						</div>
						<div class="mt-20"></div>
						<div class="flex right">
							<div><span class="button" @click="toggleEdit">{{L("修改")}}</span></div>
						</div>
					</div>
					<div class="form-box baseInfo" v-else>
						<div class="form-item unit">
							<div class="label">{{L("投稿单位")}}<span>*</span></div>
							<div class="radioGroup flex between middle">
								<label v-for="item in unitList" :key="item.type"><input type="radio" name="unit" :value="item.type" v-model="formData.unitType"/>{{L(item.type)}}</label>
							</div>
							<div v-for="item in unitList" :key="item.name" >
								<template v-if="item.type == formData.unitType">
									<div class="form-item"  :class="{error:!formRule.unitName}">
										<div class="label">{{L(item.name)}}<span>*</span>
											<span class="form-tips" v-if="item.nameTips">{{L("（请谨慎填写；此项将在年鉴索引页、入选名单、证书等中呈现）")}}</span>
										</div>
										<div class="input"><input placeholder="" type="text" v-model="formData.unitName" @change="checkEmpty('unitName')" ref="unitName" name="unitName" autocomplete="off"/></div>
									</div>
									<div class="form-item" :class="{error:!formRule.unitTeam}" v-if="item.team" >
										<div class="label">{{L(item.team)}}<span>*</span>
											<span class="form-tips" v-if="item.nameTips">{{L("（请谨慎填写；此项将在年鉴索引页、入选名单、证书等中呈现）")}}</span>
										</div>
										<div class="input"><input placeholder="" type="text" v-model="formData.unitTeam" @change="checkEmpty('unitTeam')" ref="unitTeam" name="unitTeam" autocomplete="off"/></div>
									</div>
								</template>
							</div>
							
						</div>
						
						<div class="form-item e-mail" :class="{error:!formRule.email}">
							<div class="label">{{L("联系邮箱")}}<span>*</span></div>
							<div class="input"><input placeholder="E-Mail" type="text" v-model="formData.email" @change="checkEmail" ref="email" name="email" autocomplete="off"/></div>
						</div>
						
						<div class="form-item city" :class="{error:!formRule.city}">
							<div class="label">{{L("所在城市")}}<span>*</span></div>
							<div class="input"><input placeholder="City" type="text" v-model="formData.city" @change="checkEmpty('city')" ref="city" name="city" autocomplete="off"/></div>
						</div>
						<div class="form-item address" :class="{error:!formRule.address}">
							<div class="label">{{L("通信地址")}}<span>*</span></div>
							<div class="input"><input placeholder="Add" type="text" v-model="formData.address" @change="checkEmpty('address')" ref="address" name="address" autocomplete="off"/></div>
						</div>
						<div class="form-item tel">
							<div class="label">{{L("联系电话")}}</div>
							<div class="input"><input placeholder="Tel" type="tel" v-model="formData.tel" ref="tel" name="tel" autocomplete="off"/></div>
						</div>
						<div class="form-item wechat">
							<div class="label">{{L("微信")}}</div>
							<div class="input"><input placeholder="Wechat" type="text" v-model="formData.wechat" ref="wechat" name="wechat" autocomplete="off"/></div>
						</div>
						<div class="form-item website">
							<div class="label">{{L("官网")}}</div>
							<div class="input"><input placeholder="Website" type="text" v-model="formData.website" ref="website" name="website" autocomplete="off"/></div>
						</div>
						
						
						<div class="form-btn">
							<div class="flex right">
								<div><span class="button dark" :class="{loading}" @click="doSubmit">{{L("保存")}}</span></div>
							</div>
						</div>
					</div>
					
					
						
					<div class="">
						<div class="mt-20"></div>
						<div class="mt-20"></div>
						<div class="mt-20 isw"></div>
						
						<WorkList class="isw" ref="WorkList"></WorkList>
						
						<div class="isw add-box">
							<div class="flex right middle">
								<span class="button dark " @click="toWorkPage">
									<div class="flex middle">
										<img class="icon" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi4zMzkiIGhlaWdodD0iMTYuMzM5IiB2aWV3Qm94PSIwIDAgMTYuMzM5IDE2LjMzOSI+CiAgPGcgaWQ9Iue7hF80OCIgZGF0YS1uYW1lPSLnu4QgNDgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjA4LjUyNCAtMTMzOC4zMzEpIj4KICAgIDxjaXJjbGUgaWQ9IuakreWchl80NCIgZGF0YS1uYW1lPSLmpK3lnIYgNDQiIGN4PSI4LjE3IiBjeT0iOC4xNyIgcj0iOC4xNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIwOC41MjQgMTMzOC4zMzEpIiBmaWxsPSIjZmZmIi8+CiAgICA8cmVjdCBpZD0i55+p5b2iXzE0MSIgZGF0YS1uYW1lPSLnn6nlvaIgMTQxIiB3aWR0aD0iOC41MDQiIGhlaWdodD0iMS43NjkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyMTIuNDQyIDEzNDUuNjE2KSIvPgogICAgPHJlY3QgaWQ9IuefqeW9ol8xNDIiIGRhdGEtbmFtZT0i55+p5b2iIDE0MiIgd2lkdGg9IjguNTA0IiBoZWlnaHQ9IjEuNzY5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjE3LjU3OCAxMzQyLjI0OCkgcm90YXRlKDkwKSIvPgogIDwvZz4KPC9zdmc+Cg==" />
										{{L("添加作品")}}
									</div>
								</span>
							</div>
						</div>
						<div class="mt-50 isw"></div>
						<div class="mt-50 isw"></div>
						
					</div>
						
					
				</template>
				
				
			</div>
		</div>
	</div>
  
</template>

<script>
import MainMenu from '/src/views/MainMenu';
import WorkList from '/src/components/WorkList.vue';
export default {
  name: 'Page-User-Home',
  components: {
    MainMenu,
		WorkList
  },
  data(){
    return {
			unitList:[
				{type:"设计师个人投稿",name:"设计师个人姓名",nameTips:true},
				{type:"设计师组合投稿",name:"设计师组合主要成员姓名"},
				{type:"设计工作室/公司投稿",name:"设计工作室/公司名称",nameTips:true},
				{type:"品牌方投稿",name:"品牌方名称",team:"设计团队名称",nameTips:true},
			],
			formRule:{
				unitType:true,
				unitName:true,
				unitTeam:true,
				
				email:true,
				city:true,
				address:true,
				tel:true,
				wechat:true,
				website:true,
				
			},
			formData:
			// {"unitType":"品牌方投稿","unitName":"张三","unitTeam":"路人甲集团","email":"404919994@qq.com","city":"广州市","address":"芳村大道下市直街1号信义会馆5A2 广州餐道信息科技有限公司","tel":"17666152472","wechat":"Music1949","website":"http://www.baidu.com"},
			{
				unitType:"设计师个人投稿",
				unitName:"",
				unitTeam:"",
				email:"",
				city:"",
				address:"",
				tel:"",
				wechat:"",
				website:"",
				
			},
			isEdit:true,
			hasBaseInfo:false,
			dataLoading:true,
			loading:false
    };
  },
  async mounted () {
		var userData = this.$root.userData;
		var isLogin = this.$root.isLogin;
		if(!userData||!userData.config||!userData.config.unitName){
			this.isEdit = true;
		} else {
			this.hasBaseInfo = true;
			this.isEdit = false;
		}
		this.dataLoading = false;
  },
  methods: {
		toWorkPage(){
			if(!this.$root.CONFIG.onSubmit){
				alert("网站暂停提交作品. 具体开放时间请留意网站信息.");
				return;
			}
			if(this.$refs.WorkList.list.length>=10){
				alert(this.L('一位投稿者最多只能提交10份作品'));
				return;
			}
			if(!this.hasBaseInfo){
				alert("请先保存投稿信息");
				return ;
			} else {
				this.$root.toPage('/User/Work');
			}
		},
		toggleEdit(){
			this.isEdit = !this.isEdit;
			if(this.isEdit){
				var userData = this.$root.userData;
				if(!userData.config){
					userData.config = {};
				}
				for(var key in userData.config){
					if(typeof this.formData[key] !== 'undefined'){
						this.formData[key] = userData.config[key];
					}
				}
			}
		},
		checkEmpty(target){
			if(!this.formData[target]){
				this.formRule[target] = false;
				(this.$refs[target][0]||this.$refs[target]).focus();
				return false;
			}
			this.formRule[target] = true;
			return true
		},
		checkEmail(){
			if(!this.$root.checkEmail(this.formData.email)){
				this.formRule.email = false;
				this.$refs.email.focus();
				return false;
			}
			this.formRule.email = true;
			return true;
		},
		checkAll(){
			if(!this.checkEmail()){
				alert(this.L("电子邮箱格式有误"));
				return false;
			}
			return this.formData.unitType && this.checkEmpty('unitName') && this.checkEmpty('city') && this.checkEmpty('address')
		},
		doSubmit(){
			if(!this.checkAll()){return false;}
			if(this.formData.unitType == '品牌方投稿' && !this.checkEmpty('unitTeam')){
				return false;
			}
			this.loading = true;
			
			var formData = {...this.formData};
			this.request.post('/api/v1/frontDeskUser/editConfig',{config:JSON.stringify(formData)}).then(res=>{
				console.log(res);
				
				this.loading = false;
				if(res){
					alert(this.L("保存成功!"));
					window.location.reload()
				}
			});
			
		}
		
  }
}
</script>

<style scoped lang="less">
#Page-User-Home{
	
	.info-box{
		font-size:16px;
		line-height: 2;
		.unitName{
			font-size:24px;
		}
	}
	.add-box{
		font-size:16px;
	}
}
</style>